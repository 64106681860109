import { Outlet } from 'react-router-dom'
import NavBar from '../component/NavBar'
import Breadcrumbs from '../component/Breadcrumbs'
import Footer from '../component/Footer'

function RootLayout() {
  return (
    <div>
      <NavBar />
      <Breadcrumbs />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  )
}

export default RootLayout