import { NavLink, Outlet } from "react-router-dom";

function HelpLayout() {
  return (
    <div className="background text-slate-300">

    <section className="text-slate-300">
      <h1 className="mt-16">Connect</h1>

      <div>
        Coming together is a beginning, staying together is progress, and
        working together is success ~ HENRY FORD
      </div>
      <br />
      <div className="font-bold">
        Let us know how we may help:
      </div>
      <br />
      <ul
        role="list"
        class="marker:text-[green-900] list-disc pl-5 space-y-3"
      >
        <li>Have Question(s);</li>
        <li>Project(s);</li>
        <li>Recommendation(s); or</li>
        <li>Just want to keep in touch? </li>
        <br />
        <div>
          <h1 className="font-black text-slate-300">
            Contact Us Using Any Of The Means Provided Below:
          </h1>
        </div>
        <br />

        <li>One of our Representatives will be happy to discuss your needs.</li>
      </ul>

      <nav className="inline-flex w-full gap-4 justify-center items-center mt-12 m-auto text-slate-200 ">
        <NavLink to="faq" className="bg-slate-500/75">View the FAQ</NavLink>
        <NavLink to="contact" className="bg-slate-500/75">Contact US</NavLink>
      </nav>
      <Outlet />
    </section>
    </div>
  );
}

export default HelpLayout;
