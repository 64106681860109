// import { Download } from "@mui/icons-material";
import React from "react";
import Profile from "../images/download.jpeg";
import Alode7 from "../images/Alode7.png";

function About() {
  return (
    <div className="background">
      <section className="text-slate-300 py-24">
        
            <h1 className="text-4xl font-bold mb-4 animate-shake animate-infinite animate-duration-[5000ms] animate-delay-0 animate-ease-linear animate-normal animate-fill-both">
              About Us
            </h1>
            <div className="flex flex-col lg:flex-row">
          <div className="xl:w-1/2 md:text-justify">
            <div>
              <b>ONETURF SOLUTIONS LIMITED (RC No: 6919497)</b> is a fully-owned
              Nigerian service company. Our focus is to engage our specialized,
              technical expertise and motivate our team of professionals in
              contributing to the businesses of our clients as relates to the
              Environment.
            </div>
            <br />

            <div>
            <b>ONETURF</b> is led by seasoned professionals with extensive experience
              that lends credence to the commiserate value we bring. Our culture
              is that of dedication and prompt service delivery.
            </div>
            <br />

            <div>
            <b>We</b> are focused on providing <b>World-Class Environmental Solutions</b> to
              our Clients and Partners.
            </div>
          </div>
            <img
              className="w-100% pt-4 lg:pt-0 lg:pl-8 rounded bg-contain"
              src={Alode7}
              alt="HyprepProject1"
            />
            
        </div>

        <div className="md:flex flex-row mt-16 gap-12 w-full">
          <div className="md:w-1/2 mb-8 md:">
            <h1 className="mb-2 text-3xl font-bold animate-shake animate-infinite animate-duration-[5000ms] animate-delay-0 animate-ease-linear animate-normal animate-fill-both">
              Our Solutions Covers:
            </h1>
            <ul
              role="list"
              class="marker:text-green-500 list-disc pl-5 space-y-3 "
            >
              <li>Environmental Management, Training, and Consultancy</li>
              <li>Operations and Maintenance</li>
              <li>Procurement and Logistics Services</li>
              <li>Renewable Energy</li>
              <li>Software Solutions</li>
            </ul>
          </div>

          <div className="md:w-1/2">
            <h1 className="mb-2 text-3xl font-bold animate-shake animate-infinite animate-duration-[5000ms] animate-delay-0 animate-ease-linear animate-normal animate-fill-both">
              Our Team
            </h1>
            <p>
              Members of our team include, but not limited to, Accredited Expert
              Consultants, Senior Risk Assessor, Environmental Engineers,
              Biologist, Chemist, Geoscientists, Project Managers, as well as
              Project Technologists that are well experienced to provide
              professional environmental and consulting services.
            </p>
          </div>
        </div>

        <div className="md:flex flex-row mt-12 gap-12 content-center">
          <div className="mb-8 md:mb-0 md:w-1/2">
            <h1 className="mb-2 text-3xl font-bold animate-shake animate-infinite animate-duration-[5000ms] animate-delay-0 animate-ease-linear animate-normal animate-fill-both">
              Our Philosophy
            </h1>
            Exceeding great heights through international best practices!
          </div>

          <div className="md:w-1/2">
            <h1 className="mb-2 text-3xl font-bold animate-shake animate-infinite animate-duration-[5000ms] animate-delay-0 animate-ease-linear animate-normal animate-fill-both">
              Our Mission
            </h1>
            <p>
              To restore the Earth, ensuring a Pollution-Free Environment and
              proper coexistence of its inhabitants in the Ecosystem!
            </p>
          </div>
        </div>

        <h1 className="mb-4 mt-16 text-3xl font-bold animate-shake animate-infinite animate-duration-[5000ms] animate-delay-0 animate-ease-linear animate-normal animate-fill-both">
          Our Core Values:
        </h1>
        <div className="lg:flex flex-row gap-6">
          <div>
            <h2 className="text-2xl font-bold">Safety & Environment</h2>
            <div>
              <b>We</b> strive for the highest Health, Safety, Security and
              Environment standards. Safety of our workforce, assets,
              communities, and the enironment in which we operate is of highest
              consideration.
            </div>
          </div>
          <br />
          <div>
            <h2 className="text-2xl font-bold">People</h2>
            <div>
              <b>While</b> we strive to achieve the best in everything we do,
              people and the environment come first as we create an innovative
              work environment that keeps our people motivated and encourages
              continuous learning.
            </div>
          </div>

          <br />
          <div>
            <h2 className="text-2xl font-bold">
              Integrity, Excellence & Teamwork
            </h2>
            <div>
              <b>We</b> hold ourselves to the highest ethical standards of
              trust, fairness, and ethics. As part of our culture, our successes
              in business is based on the quality of our services. We prosper
              only to the degree that we serve our clients well —and treat them,
              our coworkers, our customers, our vendors, our sub contactors and
              our competitors fairly and honestly.
            </div>
          </div>
        </div>

        <div className="text-white flex flex-col items-center mt-20 font-bold">
          <p className="text-xl">Click to download Company Profile:</p>
          <a
            href="https://drive.google.com/file/d/12DpGto0nbUdrN0da71bx80ec8EzSC0sz/view?usp=sharing"
            download="OneTurfProfile"
          >
            <img
              className="rounded mt-2 hover:scale-110 transition duration-300 ease-in-out"
              src={Profile}
              alt="OneTurf Profile"
              width="150"
              height="120"
            />
          </a>
        </div>
      </section>
    </div>
  );
}

export default About;
