import React from "react";
// import Logo from "../images/Logo-OneTurf.png";
// import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";


export default function Footer() {
  return (
    <div className="h-44 w-full flex opacity-70 bg-green-700 py-4 content-center text-center items-center">
      {/* <div className="w-12 ml-auto my-auto mr-0"> // footer former color [#004B3C]
        <a href="#">
          <img src={Logo} alt="" />
        </a>
      </div> */}

      <div className="text-sm text-white w-full ">
        All Rights Reserved ©2024{" "}
        <span className="text-white font-black">
          {" "}
          <br />
        </span>
        <a href="#" className="text-white  font-extrabold hover:text-green-200">
          ONETURF SOLUTIONS LIMITED
        </a>{" "}
        <p>#13 Ovunwo Street, D/Line, Port Harcourt</p>
        
        <div className="flex flex-row  justify-center"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
  <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
</svg> <p className="pl-1 sm:pr-4 pr-2 hover:opacity-70" ><a href="mailto:info@oneturfsolutions.com">info@oneturfsolutions.com</a></p> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
  <path fillRule="evenodd" d="M19.5 9.75a.75.75 0 01-.75.75h-4.5a.75.75 0 01-.75-.75v-4.5a.75.75 0 011.5 0v2.69l4.72-4.72a.75.75 0 111.06 1.06L16.06 9h2.69a.75.75 0 01.75.75z" clipRule="evenodd" />
  <path fillRule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clipRule="evenodd" />
</svg> <a className="hover:opacity-70" href="tel:+2348038683694">+2348038683694</a></div>

        <div className="flex flex-row mt-3 around space-x-6 justify-center">

        {/* <a href="https://twitter.com/oneturf">
          <TwitterIcon fontSize="large"/> </a> */}

              <a className="hover:opacity-70" href="https://twitter.com/oneturf" target="_blank" rel="noreferrer"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="35" height="35" viewBox="0 0 50 50">
<path d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z"></path>
</svg></a>

<a href="https://www.linkedin.com/company/oneturfsolutions" target="_blank" rel="noreferrer"> <LinkedInIcon className="hover:opacity-70" fontSize="large"/></a>

          <a href="https://www.instagram.com/oneturfsolutions" target="_blank" rel="noreferrer"> <InstagramIcon className="hover:opacity-70" fontSize="large"/></a>
      
          <a href="https://www.facebook.com/oneturfSolutions" target="_blank" rel="noreferrer"> <FacebookRoundedIcon className="hover:opacity-70" fontSize="large"/></a>
        </div>
      </div>
    </div>
  );
}
