import React from "react";
import Alode1 from "../images/Alode1.png";
import Alode2 from "../images/Alode2.png";
import Alode3 from "../images/Alode3.png";
import Alode4 from "../images/Alode4.png";
import Alode5 from "../images/Alode5.png";
import Alode6 from "../images/Alode6.png";
import Alode7 from "../images/Alode7.png";
import Akpajo from "../images/Akpajo.png";
import Ogale1 from "../images/Ogale1.png"
import Ogale2 from "../images/Ogale2.png"
import Ogale3 from "../images/Ogale3.png"
import Ogale4 from "../images/Ogale4.png"
import Ogale5 from "../images/Ogale5.png"
import Ogale6 from "../images/Ogale6.png"
import Ogale7 from "../images/Ogale7.png"

function Projects() {
  return (
    //
    <div className="background text-slate-300">
    <section>
      <h1 className="mt-16">Projects</h1>
      <div className="my-4 flex divide-x hover:divide-y-8 divide-green-300 items-center">
        <h1 className="text-xl font-bold pr-2"> Project One: </h1>
        <p className="flex text-base font-normal pl-2 grow">
          Clean-Up and Remediation of Oil Spill Site at Alode, Eleme, Ogoniland,
          Rivers State, Nigeria. (LOT 005)
        </p>{" "}
      </div>

      <div>
        <img className="w-full" src={Alode1} alt="HyprepProject1" />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3  mb-32">
        <img
          className="w-full md:h-[250px]"
          src={Alode2}
          alt="HyprepProject1"
        />
        <img
          className="w-full md:h-[250px]"
          src={Alode3}
          alt="HyprepProject1"
        />
        <img
          className="w-full md:h-[250px]"
          src={Alode4}
          alt="HyprepProject1"
        />
        <img
          className="w-full md:h-[250px]"
          src={Alode5}
          alt="HyprepProject1"
        />
        <img
          className="w-full md:h-[250px]"
          src={Alode6}
          alt="HyprepProject1"
        />
        <img
          className="w-full md:h-[250px]"
          src={Alode7}
          alt="HyprepProject1"
        />
      </div>

      <div className="mb-4 flex divide-x hover:divide-y-8 divide-green-300 items-center">
        <h1 className="text-xl font-bold pr-2"> Project Two: </h1>
        <p className="flex text-base font-normal pl-2 grow">
        Clean-Up and Remediation of Oil Spill Site in Ajeokpori, Ogale, Eleme,
          Ogoniland, Rivers State, Nigeria. (SMR LOT 003)
        </p>{" "}
      </div>

      <div>
        <img className="w-full mb-4" src={Akpajo} alt="HyprepProject1" />
      </div>
      <div>
        <img className="w-full" src={Ogale1} alt="HyprepProject1" />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3  mb-32">
        <img
          className="w-full md:h-[250px]"
          src={Ogale2}
          alt="HyprepProject1"
        />
        <img
          className="w-full md:h-[250px]"
          src={Ogale3}
          alt="HyprepProject1"
        />
        <img
          className="w-full md:h-[250px]"
          src={Ogale4}
          alt="HyprepProject1"
        />
        <img
          className="w-full md:h-[250px]"
          src={Ogale5}
          alt="HyprepProject1"
        />
        <img
          className="w-full md:h-[250px]"
          src={Ogale6}
          alt="HyprepProject1"
        />
        <img
          className="w-full md:h-[250px]"
          src={Ogale7}
          alt="HyprepProject1"
        />
      </div>
    </section>
    </div>
  );
}

export default Projects;
