import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from "react-router-dom";

// pages
import Home from './pages/Home'
import About from './pages/About'
import Projects from './pages/Projects'
import Faq from "./pages/connect/Faq";
import Contact, { contactAction } from "./pages/connect/Contact";
import NotFound from "./pages/NotFound";
import Careers from "./pages/Careers";

// layouts
import RootLayout from "./layouts/RootLayout";
import HelpLayout from "./layouts/HelpLayout";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<RootLayout />}>
      <Route index element={<Home />} />
      <Route path="about" element={<About />} />
      <Route path="projects" element={<Projects />} />

      <Route path='connect' element={<HelpLayout />}>
        <Route path='faq' element={<Faq />} />
        <Route path='contact' element={<Contact />} action={contactAction} />
      </Route>

      <Route path="careers" element={<Careers />} />

      <Route path="*" element={<NotFound />} />
    </Route>
  )
)

function MyRoutes() {
  return (
    <RouterProvider router={router} />
  );
}

export default MyRoutes