import React from "react";
import Cover from "../images/Cover3.jpeg";
import EarthPro from "../images/EARTHPRO.jpeg";
import HYPREP from "../images/HYPREP.png";
import FME from "../images/FME.png";
import Unirem from "../images/unirem.jpeg";
import BeachStone from "../images/beach-stone.png";
import EcoSafe from "../images/ecosafe.png";
import Environment from "../images/eco.jpeg";
import Digital from "../images/future-proofing.jpeg";
import MRO from "../images/restore.jpeg";
import Procure from "../images/procurement.png";
import NUPRC from "../images/NUPRC.png";
import OGISP from "../images/OGISP.jpeg";

function Home() {
  return (
    <div>
      <div className="relative -z-10 mb-0 pb-0">
        <img
          src={Cover}
          alt="coverImage"
          className="bg-contain lg:bg-cover lg:w-full h-screen object-cover lg:h-full bg-fixed w-full"
        />
        <section className="text-white font-bold lg:text-center items-center absolute right-0 left-0 top-12 lg:top-1/3">
          <h1 className="text-5xl lg:text-7xl font-black animate-shake animate-infinite animate-duration-[5000ms] animate-delay-0 animate-ease-linear animate-normal animate-fill-both">
            Solutions That Make Life Better
          </h1>
          <p className="text-4xl lg:text-5xl mt-8 font-black">
            Welcome To{" "}
            <span className="text-green-300 font-black">
              ONETURF SOLUTIONS LTD
            </span>
            ,{" "}
            <span className="text-4xl lg:text-5xl">
              Providing Eco-Friendly Solutions To Curb Environmental Challenges.
            </span>
          </p>
        </section>
      </div>

      <div className="home-background -my-12">
        <section className="pt-24 z-0">
          <h1 className="w-full text-center md:text-5xl text-white font-black">
            Solutions We Offer ...
          </h1>
          <div className="lg:flex flex-row sm:grid grid-cols-2 gap-4">
            <div className="w-full relative mb-4">
              <img
                src={Environment}
                className="w-full h-64 object-cover rounded opacity-50"
              />
              <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-green-700 bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-70"></div>
              <div className="absolute top-2 text-white p-4">
                <h1 className="font-black text-3xl">
                  Environmental <br />
                  Solutions
                </h1>
                <div className="font-bold text-white">
                  Our primary goal is to restore Earth's stability and ecosystem
                  functions.
                  <br />
                  <br />
                  <a
                    href="https://drive.google.com/file/d/12DpGto0nbUdrN0da71bx80ec8EzSC0sz/view?usp=sharing"
                    download="OneTurfProfile"
                    className="font-black hover:text-green-400"
                    target="_blank" rel="noreferrer"
                  >
                    Learn More...
                  </a>
                </div>
              </div>
            </div>

            <div className="w-full relative mb-4">
              <img
                src={Digital}
                className="w-full h-64 object-cover rounded opacity-50"
              />
              <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-green-700 bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-70"></div>
              <div className="absolute top-2 text-white p-4">
                <h1 className="font-black text-3xl">
                  Digital <br />
                  Solutions
                </h1>
                <div className="font-bold">
                  Our Secondary goal is to use digital technology to aid the
                  restoration of Earth.
                </div>
                <br />
                <a
                  href="https://drive.google.com/file/d/12DpGto0nbUdrN0da71bx80ec8EzSC0sz/view?usp=sharing"
                  download="OneTurfProfile"
                  className="font-black hover:text-green-400"
                  target="_blank" rel="noreferrer"
                >
                  Learn More...
                </a>
              </div>
            </div>

            <div className="w-full relative mb-4">
              <img src={MRO} className="w-full h-64 lg:object-cover rounded" />
              <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-green-700 bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-70"></div>
              <a
                href="https://drive.google.com/file/d/12DpGto0nbUdrN0da71bx80ec8EzSC0sz/view?usp=sharing"
                download="OneTurfProfile"
                className="font-black text-white absolute bottom-2 right-10 hover:text-green-400"
                target="_blank" rel="noreferrer"
              >
                Learn More...
              </a>
            </div>

            <div className="w-full relative mb-4">
              <img
                src={Procure}
                className="w-full h-64 lg:object-cover rounded transition transform hover:-translate-y-1"
              />
              <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-green-700 bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-70"></div>
              <a
                href="https://drive.google.com/file/d/12DpGto0nbUdrN0da71bx80ec8EzSC0sz/view?usp=sharing"
                download="OneTurfProfile"
                className="font-black text-white absolute bottom-2 right-10 hover:text-green-400"
                target="_blank" rel="noreferrer"
              >
                Learn More...
              </a>
            </div>
          </div>
        </section>

        <section className="py-16">
          <h1 className="w-full text-center md:text-5xl lg:text-center text-white shadow-black font-black mb-6">
            Whom We’ve Worked With...
          </h1>{" "}
          <div className="grid grid-cols-2 gap-y-8 gap-x-4 lg:flex lg:flex-row lg:gap-4 content-center items-center">
            <div className="flex mx-auto">
              <a href="https://www.earthproservices.com/" target="_blank" rel="noreferrer">
                <img
                  src={EarthPro}
                  className="relative"
                  alt="EarthProLogo"
                  className="w-52 h-20 hover:opacity-70 rounded hover:scale-110 transition delay-150 duration-300 ease-in-out"
                />
              </a>
            </div>
            <div className="flex mx-auto">
              <a href="https://hyprep.gov.ng/" target="_blank" rel="noreferrer">
                <img
                  src={HYPREP}
                  alt="HYPREPLogo"
                  className="w-52 h-20 hover:opacity-70 rounded hover:scale-110 transition delay-150 duration-300 ease-in-out" 
                />
              </a>
            </div>
            <div className="flex mx-auto">
              <a href="https://environment.gov.ng/" target="_blank" rel="noreferrer">
                <img
                  src={FME}
                  alt="FMELogo"
                  className="w-52 h-20 hover:opacity-70 rounded hover:scale-110 transition delay-150 duration-300 ease-in-out"
                />
              </a>
            </div>
            <div className="flex mx-auto">
              <a href="https://unireminc.com/" target="_blank" rel="noreferrer">
                <img
                  src={Unirem}
                  alt="UniremLogo"
                  className="w-52 h-20 hover:opacity-70 rounded hover:scale-110 transition delay-150 duration-300 ease-in-out"
                />
              </a>
            </div>
            <div className="flex mx-auto">
              <a href="https://ecosafeservicesltd.com/" target="_blank" rel="noreferrer">
                <img
                  src={EcoSafe}
                  alt="EcoSafeLogo"
                  className="w-52 h-20 hover:opacity-70 rounded hover:scale-110 transition delay-150 duration-300 ease-in-out"
                />
              </a>
            </div>
            <div className="flex mx-auto">
              <a href="https://beachstoneng.com/" target="_blank" rel="noreferrer">
                <img
                  src={BeachStone}
                  alt="BeachStoneLogo"
                  className="w-52 h-20 hover:opacity-70 rounded hover:scale-110 transition delay-150 duration-300 ease-in-out"
                />
              </a>
            </div>
          </div>
        </section>
        <section className="pb-32">
          <div className="flex flex-col mx-auto content-center items-center">
          <h1 className="w-full text-center text-4xl md:text-5xl lg:text-center text-white shadow-black font-black mb-6">
            Accreditations and Permits
          </h1>
          <div  className="md:flex">
            {" "} <a href="https://drive.google.com/file/d/1DaTjhKSgMcSqWqI-u_McV6swuuMl07xC/view?usp=drive_link" target="_blank" rel="noreferrer">
            <img
              src={NUPRC}
              className="w-80 h-24 rounded-t-lg md:rounded-none md:rounded-l-lg hover:rounded-lg hover:opacity-70 animate-shake animate-infinite animate-duration-[5000ms] animate-delay-0 animate-ease-linear animate-normal animate-fill-both hover:scale-110"
            />
            </a>
            {" "} <a href="https://drive.google.com/file/d/1DaTjhKSgMcSqWqI-u_McV6swuuMl07xC/view?usp=drive_link" target="_blank" rel="noreferrer">
            <img
              src={OGISP}
              className="w-80 h-24 rounded-b-lg md:rounded-none md:rounded-r-lg hover:rounded-lg hover:opacity-70 animate-shake animate-infinite animate-duration-[5000ms] animate-delay-0 animate-ease-linear animate-normal animate-fill-both hover:scale-110"
            />
            </a>
            
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Home;
