import React from "react";

function Faq() {
  return (
    <section className="p-0">
      <h1>Faq</h1>
      <div className="md:flex gap-4">
        <div className="w-full">
          <details className="p-4 bg-sky-800 mb-4 text-white md:flex rounded">
            <summary className="font-bold cursor-pointer">
              Where are we Located?
            </summary>
            <p className="p-4 bg-sky-700 mt-8 rounded">
              Oneturf Solutions Limited is primarily located in the oil city of Port Harcourt,
              Nigeria, where we have our head office. However, we work mostly
              anywhere in the field, by all means managing, cleaning, and restoring the
              Earth's Environement as well as providing applicable needs.
            </p>
          </details>
          <details className="p-4 bg-sky-800 mb-4 text-white md:flex rounded">
            <summary className="font-bold cursor-pointer">
              What are the core services we offer?
            </summary>
            <p className="p-4 bg-sky-700 mt-8 rounded">
              <li>
                To Carry On Business Of Environmental Management Training
                Consultancy
              </li>
              <li>
                To Carry On Business Of Protection, Clean-up, Improvement,
                Rehabilitation, Reclamation, Assessment (EIA), Mitigation, Recovery,
                Remediation, Replacement/re-introduction, Re-creation, And
                Enhancement Of Environment Affected By Any Pollution On Shore Or
                Off Shore.
              </li>
              <li>
                To Carry On The Business Of Waste Management, Waste Management
                Training And Consultancy.
              </li>
              <li>
                To Carry On Business Of Provision Of General Sustainability,
                Logistics, And Maintenance Solutions To Individuals And
                Organizations.
              </li>
              <li>
                To Carry On The Business Of Servicing Of Wells, Flow Lines
                Underwater Pipelines, Storage Facilities Gathering Lines,
                Separation Equipment, Auxiliary Non-transportation Related
                Equipment, Drilling Or Work-over Equipment And Facilities
                Serving A Geographical Oil And Gas Field.
              </li>
              <li>
                To Carry On The Business Of Renewable Energy Including Sales And
                Services.
              </li>
              <li>
                To Carry On Business Of Creating, Building, And Using Software
                And Web Applications, As May Be Considered Necessary, For The
                Attainment Of Any Of The Above Or Other Objects.
              </li>
              <li>
                To Carry On The Business Of Agriculture, Farming And Food Supply
              </li>
              <li>
                To Borrow Or Raise Money In Such Manner As The Company Shall
                Think Fit For The Purposes Of Its Business Objectives
              </li>
              <li>
                To Do All Such Other Things As May Be Considered To Be
                Incidental Or Conducive To The Attainment Of The Above
                Objectives or Any Of Them.
              </li>
            </p>
          </details>
          <details className="p-4 bg-sky-800 mb-4 text-white md:flex rounded">
            <summary className="font-bold cursor-pointer">
              Who are our core staff?
            </summary>
            <p className="p-4 bg-sky-700 mt-8 rounded">
              Oneturf Solutions Limited is led by seasoned professionals with
              extensive experiences that lends credence to the commiserate value
              we bring. Our culture is that of dedication and prompt service
              delivery.
            </p>
          </details>
        </div>
        <div className="w-full">
          <details className="p-4 bg-sky-800 mb-4 text-white md:flex rounded">
            <summary className="font-bold cursor-pointer">
              What projects have we carried out?
            </summary>
            <p className="p-4 bg-sky-700 mt-8 rounded">
              Oil Spill Response and Cleanup:
              <li>
                Oil Spill Cleanup and Remediation of Hydrocarbon Impacted Site,
                Alode, Eleme, Ogoni, initiated by Hydrocabon Pollution
                Remediation Project [HYPREP]
              </li>
              <br />
              Oil Spill Response and Cleanup of Soil and Groundwater:
              <li>
                Oil Spill Cleanup and Remediation of Hydrocarbon Impacted of
                Soil and Groundwater at Ogale, Eleme, Ogoni, initiated by
                Hydrocabon Pollution Remediation Project [HYPREP]
              </li>
            </p>
          </details>
          <details className="p-4 bg-sky-800 mb-4 text-white md:flex rounded">
            <summary className="font-bold cursor-pointer">
              Who are our partners?
            </summary>
            <p className="p-4 bg-sky-700 mt-8 rounded">
            <a href="https://www.earthproservices.com/" target="_blank" rel="noreferrer" className="hover:text-green-400">EarthPro, Africa</a>
              <br/>
              <a href="https://unireminc.com/" target="_blank" rel="noreferrer" className="hover:text-green-400">UniRemTech LLC, USA.</a>
              <br/>
              <a href="https://ecosafeservicesltd.com/" target="_blank" rel="noreferrer" className="hover:text-green-400">Ecosafe, Africa/UK</a>
              <br/>
              <a href="https://beachstoneng.com/" target="_blank" rel="noreferrer" className="hover:text-green-400">Beachstone Limited, Africa</a>
            </p>
          </details>
          <details className="p-4 bg-sky-800 mb-4 text-white md:flex rounded">
            <summary className="font-bold cursor-pointer">
              How do we commence a project?
            </summary>
            <p className="p-4 bg-sky-700 mt-8 rounded">
            Typically, we carry out an Assessment first. That helps us draw up a plan and a helpful budget and quotation. In events of emergency responses, we do all on the fly. Especially so to save lives and the Environement.
             <br/>
             <br/>
              Do get in touch should you have more inquires or need our professional services.
            </p>
          </details>
        </div>
      </div>
    </section>
  );
}

export default Faq;
