import { Link, useLocation } from "react-router-dom"

function Breadcrumbs() {
  const location = useLocation()

  let currentLink = ''

  const crumbs = location.pathname.split('/')
    .filter(crumb => crumb !== '')
    .map(crumb => {
      currentLink += `/${crumb}`

      return (
        <div className="after:content-['>'] after:ml-2 before:last:ml-2 after:last:hidden text-green-700 text-lg capitalize underline hover:text-green-900 hover:no-underline transition ease-in-out duration-300 z-10" key={crumb}>
          <Link to={currentLink}>{crumb}</Link>
        </div>
      )
    })


  return (
    <div className="max-w-[1200px] flex mx-auto mt-24 px-3">
      {crumbs}
    </div>
  )
}

export default Breadcrumbs