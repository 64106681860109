// import React from 'react'
import { NavLink } from "react-router-dom";
import Logo from "../images/Logo-OneTurf.png";
import { Bars3BottomRightIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

function NavBar() {
  let [isOpen, setisOpen] = useState(false);

  return (
    <header className="fixed top-0 left-0 right-0 bg-black opacity-80 z-20 w-screen">
      <nav className="md:flex text-white items-center justify-center text-center max-w-screen-xl h-24">
        <div className="w-full flex items-center my-auto py-3">
          <a href="/">
            <img src={Logo} alt="OneTurf Logo" className="max-w-16 max-h-16" />
          </a>
          <h1 className="text-2xl font-bold md:text-3xl text-left md:font-bold">Oneturf Solutions Ltd</h1>
        </div>

        <div
          onClick={() => setisOpen(!isOpen)}
          className="w-8 h-8 absolute right-3 top-9 cursor-pointer md:hidden"
        >
          {isOpen ? <XMarkIcon /> : <Bars3BottomRightIcon /> }
        </div>

        <div className={`flex flex-col md:flex md:flex-row gap-8 md:gap-4 md:items-center bg-black pt-16 md:py-0 pb-8 absolute md:static md:z-auto z-[-11] left-0 w-full md:w-auto md:pl-0 px-4 transition-all duration-500 ease-in ${isOpen ? 'top-12' : 'top-[-490px]'}`}>
          <NavLink onClick={() => setisOpen(!isOpen)} to="/"> Home</NavLink>
          <NavLink onClick={() => setisOpen(!isOpen)} to="about">About</NavLink>
          <NavLink onClick={() => setisOpen(!isOpen)} to="projects">Projects</NavLink>
          <NavLink onClick={() => setisOpen(!isOpen)} to="connect">Connect</NavLink>
          <NavLink onClick={() => setisOpen(!isOpen)} to="careers">Careers</NavLink>
        </div>
     
      </nav>
    </header>
  );
}

export default NavBar;
