import { Link, useRouteError } from "react-router-dom";

function Careers() {
  return (
    <div className="background">

    <section className="h-screen text-slate-300">
      <h1 className="mt-16">Careers</h1>

      <div>
        There are currently no openings at the moment. Kindly check back another
        time.
      </div>
      <br/>

      <div className="font-bold">In the meantime, network with us:</div>

      <h3>Have a Project or Recommendation? <Link
        to="/connect" className="underline font-bold">~Contact us.</Link></h3>
      <br />
      <div className="mb-20 ">
        One of our Representatives will be happy to discuss your needs.
      </div>
      
      <Link
        to="/"
        className="py-4 px-6 rounded text-gray-800 bg-green-300 hover:text-gray-400 font-bold"
      >
        Back to the <span className="underline">Home Page</span> 🏠
      </Link>
    </section>
    </div>
  );
}

export default Careers;
