import React, { useRef } from "react";
import { Form, redirect, useActionData } from "react-router-dom";
// import emailjs from '@emailjs/broswer'

function Contact() {
  // accessing error data via
  const data = useActionData();
  const form = useRef();
//   const sendEmail = (e) => {
// e.preventDefault();
// emailjs.sendForm('service_id', 'template_id', e.target, 'public_key')
// onSubmit={sendEmail}
//   }

  return (
    <section className="p mt-24 max-w-lg text-slate-300">
      <h1>Contact</h1>
      <div className="mb-8">
        We welcome you to leave a message by using, preferably, the contact information on the <span className="font-bold bg-green-600 px-2 py-1 rounded text-white">footer below</span>  for faster respond:
      </div>
      <Form method="post" action="/connect/contact" 
      >
        <label>
          <span className="text-base">Full Name</span>
          <input
            className="w-full h-12 mb-6 pl-2"
            placeholder="Name"
            type="text"
            name="name"
            required
          />
        </label>

        <label>
          <span className="text-base">Your Email</span>
          <input
            className="w-full h-12 mb-6 pl-2"
            placeholder="Email"
            type="email"
            name="email"
            required
          />
        </label>

        <label>
          <span className="text-base">Subject</span>
          <input
            className="w-full h-12 mb-6 pl-2"
            placeholder="Subject"
            type="subject"
            name="subject"
            required
          />
        </label>

        <label>
          <span className="text-base">Your Message</span>
          <textarea
            type="message"
            name="message"
            placeholder="Message"
            className="w-full h-52 p-2"
            required
          ></textarea>
        </label>

        {data && data.error && (
          <p className="text-red-800 mt-4 font-bold">{data.error}</p>
        )}

        <button disabled className="my-8 px-8 py-2 bg-green-500 rounded text-gray-200 font-bold hover:text-gray-500 transition deactivate tooltip">
          Submit  <span class="tooltiptext">⛔️ Please Use Email Address Below</span>
        </button>
      </Form>
    </section>
  );
}

export default Contact;

export const contactAction = async ({ request }) => {
  console.log(request);

  const data = await request.formData();

  const submission = {
    name: data.get("name"),
    email: data.get("email"),
    subject: data.get("subject"),
    message: data.get("message"),
  };

  // console.log(submission);
  // send post request
  if (submission.name.length < 5) {
    return { error: "Please input a valid Name" };
  }

  if (submission.email.length < 7) {
    return { error: "Please input correct Email" };
  }
  if (submission.subject.length < 0) {
    return { error: "Please input Email Subject" };
  }
  if (submission.message.length < 10) {
    return { error: "Message must be over 10 chars long" };
  }

  // redirect the user/ from react router dom function (use / to return to home instead of error)

  return redirect("error");
};