import { Link } from "react-router-dom"

function NotFound() {
  return (
    <section className="h-screen">
      <h1>Error</h1>
      <div>
      <p className="mb-8 ">You may have typed the link you want to visit wrongly. </p>
      <br/>
      <Link to="/" className="py-4 px-6 rounded bg-green-300 hover:text-white font-bold">Back to the <span className="underline">Home Page</span> 🏠</Link>

      <p className="font-bold mt-12">Would you want to contact us? </p>
      <br/>
      <p>Kindly write us or call us directly using the contact information on the footer below. </p>  <br/>  
      <p className="font-bold">Thank you for your time and understanding!</p></div>  <br/>  <br/>

    
    </section>
  )
}

export default NotFound